import { postBodyRequest, getRequest } from '@/utils/axios'

export function getCompanyAll (params) {
  return postBodyRequest('/anal/data-cockpit/company-all', params)
}

// 省份准时率排名
export function getProvinceData (params) {
  return postBodyRequest('/anal/data-cockpit/company-province', params)
}
// 超时车辆
export function getTimeOutData (params) {
  return postBodyRequest('/anal/data-cockpit/over-due/page', params)
}
// 部门准时率排名
export function getDepartmentData (params) {
  return postBodyRequest('/anal/data-cockpit/company-org', params)
}
// 年度总准时率
export function getYearData (params) {
  return postBodyRequest('/anal/data-cockpit/company-year', params)
}
// 线路总准时率
export function getLineData (params) {
  return postBodyRequest('/anal/data-cockpit/company-line', params)
}
// 发运总准时率
export function getTransData (params) {
  return postBodyRequest('/anal/data-cockpit/company-transRate', params)
}
// 运力地图准时率
export function provinceOnTimeRate (params) {
  return postBodyRequest('/anal/trans/capacity/provinceOnTimeRate', params)
}
// 委托准时率
export function delegateOnTimeRate (params) {
  return postBodyRequest('/anal/trans/capacity/delegateOnTimeRate', params)
}
export function onTimeRateAll (params) {
  return postBodyRequest('/anal/trans/capacity/onTimeRateAll', params)
}
// 验/提/干/送车准时率
export function onTimeRate (params) {
  return postBodyRequest('/anal/trans/capacity/onTimeRate', params)
}
export function timeoutVehicle (params) {
  return postBodyRequest('/anal/trans/capacity/timeoutVehicle', params)
}