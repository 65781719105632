<template>
  <div>
    <div style="margin-bottom: 10px">
      <a-form ref="searchFormRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-month-picker :getCalendarContainer="e => e.parentNode" v-model:value="searchForm.yearMonth"
            placeholder="年/月" @change="pickerChange" :allowClear="false" />
        </a-form-item>
        <!-- <a-form-item label="">
          <a-select
            style="width: 100%"
            placeholder="业务部门"
            allowClear
            v-model:value="searchForm.superOrgId"
          >
            <a-select-option
              v-for="item in $store.state.app.orgList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item> -->
        <a-form-item label="">
          <a-space>
            <a-button :loading="loading" type="primary" @click="searchData">
              查询
            </a-button>
<!--            <a-button @click="resetData"> 重置 </a-button>-->
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="(record) => record.id" :data-source="listData" :pagination="false"  :loading="loading" bordered
      size="small" :rowKey="(record, index) => {return index;}" @change="handleTableChange" :scroll="{ y: 500 }">
      <template #overDueNum="{ record }">
        <div>
          <a v-show="Number(record.overDueNum) > 0" @click="openTimeOutData(record)">{{ record.overDueNum }}</a>
          <span v-show="Number(record.overDueNum) <= 0">{{ record.overDueNum }}</span>
        </div>
      </template>
      <template #rate="{ record }">
        <div>
          {{ record.rate + "%" }}
        </div>
      </template>
    </a-table>
  </div>
  <TimeOutTable ref="timeoutRef" :title="title" :searchFormData="searchFormData" :yearMonth="searchForm.yearMonth" :orgId="orgId" />
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from "vue"
import { getDepartmentData } from "@/api/dataCenter/monitoring"
import TimeOutTable from './timeOutTable'

export default {
  components: {
    TimeOutTable
  },
  props: {
    searchFormData: Object
  },
  setup (props) {
    const timeoutRef = ref(null)
    const state = reactive({
      loading: false,
      timeoutShow: false,
      orgId: '',
      title: '',
      listData: [],
      searchFormData: {},
      searchForm: {
        superOrgId: null,
        yearMonth: null,
        year: null
      },
      mirrorSearchForm: {},
      columns: [
        {
          title: "月份",
          dataIndex: "month",
        },
        {
          title: "部门名称",
          dataIndex: "orgName",
        },
        {
          title: "发运台量",
          dataIndex: "total",
        },
        {
          title: "超时台量",
          dataIndex: "overDueNum",
          slots: {
            customRender: "overDueNum",
          },
        },
        {
          title: "当月准时率",
          dataIndex: "rate",
          slots: {
            customRender: "rate",
          },
        },
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const setData = () =>{
      if(props.searchFormData.year) state.searchForm.year = props.searchFormData.year
      if(props.searchFormData.yearMonth) state.searchForm.yearMonth = props.searchFormData.yearMonth
      state.searchFormData = props.searchFormData
    }
    const loadData = () => {
      state.loading = true
      getDepartmentData({
        ...props.searchFormData,
        ...state.searchForm
      }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const searchData = () => {
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const pickerChange = (e, v) => {
      state.searchForm.yearMonth = v
    }
    const openTimeOutData = record => {
      state.orgId = record.orgId
      state.title = record.orgName+`(${state.searchForm.yearMonth})`
      timeoutRef.value.windowShow = true
      setTimeout(() => {
        timeoutRef.value.setData()
        timeoutRef.value.loadData()
      }, 10)
    }

    return {
      ...toRefs(state),
      timeoutRef,
      loadData,
      setData,
      searchData,
      resetData,
      pickerChange,
      openTimeOutData
    };
  },
};
</script>

<style lang="less" scoped></style>
