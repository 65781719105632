<template>
  <div>
    <div style="margin-bottom: 10px">
      <a-form ref="searchFormRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-month-picker :getCalendarContainer="e => e.parentNode" v-model:value="searchForm.yearMonth" placeholder="年/月"
            @change="pickerChange" :allowClear="false" />
        </a-form-item>
        <!--        <a-form-item label="">-->
        <!--          <a-select-->
        <!--            v-model:value="searchForm.provinceName"-->
        <!--            style="width: 150px"-->
        <!--            placeholder="选择省份"-->
        <!--            @change="handleChange"-->
        <!--          >-->
        <!--            <a-select-option-->
        <!--              v-for="item in $store.state.app.cityDict"-->
        <!--              :key="item.value"-->
        <!--              :value="item.label"-->
        <!--              >{{ item.label }}</a-select-option-->
        <!--            >-->
        <!--          </a-select>-->
        <!--        </a-form-item>-->
        <a-form-item label="">
          <a-space>
            <a-button :loading="loading" type="primary" @click="searchData">
              查询
            </a-button>
            <!--            <a-button @click="resetData"> 重置 </a-button>-->
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="(record) => record.id" :pagination="false" :data-source="listData"
      :loading="loading" bordered size="small" :rowKey="(record, index) => { return index }" @change="handleTableChange"
      :scroll="{ y: 500 }">
      <template #timeoutNum="{ record }">
        <div>
          <a v-show="record.timeoutNum > 0" @click="openTimeOutData(record)">{{ record.timeoutNum }}</a>
          <span v-show="record.timeoutNum <= 0">{{ record.timeoutNum }}</span>
        </div>
      </template>
      <template #proportionNum="{ record }">
        <div>
          {{ record.proportionNum + "%" }}
        </div>
      </template>
    </a-table>
  </div>
  <TimeOutTable ref="timeoutRef" :title="title" :searchFormData="searchFormData" :yearMonth="searchForm.yearMonth"
    :startProvinceId="startProvinceId" />
</template>

<script>
import { reactive, toRefs, ref, watch, onMounted } from 'vue'
import { getProvinceData } from '@/api/dataCenter/monitoring'
import TimeOutTable from './timeOutTable'

export default {
  components: {
    TimeOutTable
  },
  props: {
    dataList: Array,
    searchFormData: Object
  },
  setup (props) {
    const timeoutRef = ref(null)
    const state = reactive({
      loading: false,
      timeoutShow: false,
      startProvinceId: '',
      title: '',
      listData: props.dataList || [],
      searchFormData: {},
      searchForm: {
        provinceName: null,
        yearMonth: null,
        year: null
      },
      mirrorSearchForm: {},
      columns: [
        {
          title: '排名',
          dataIndex: 'rank'
        },
        {
          title: '起点省份',
          dataIndex: 'name'
        },
        {
          title: '发运台量',
          dataIndex: 'transNum'
        },
        {
          title: '超时台量',
          dataIndex: 'timeoutNum',
          slots: {
            customRender: 'timeoutNum'
          }
        },
        {
          title: '当月准时率',
          dataIndex: 'proportionNum',
          slots: {
            customRender: 'proportionNum'
          }
        }
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    watch(() => props.dataList, (newVal, oldVal) => {
      if (props.dataList) state.listData = props.dataList
    })
    const setData = () => {
      if (props.searchFormData.year) state.searchForm.year = props.searchFormData.year
      if (props.searchFormData.yearMonth) state.searchForm.yearMonth = props.searchFormData.yearMonth
      state.searchFormData = props.searchFormData
    }
    const loadData = () => {
      state.loading = true
      getProvinceData({
        ...props.searchFormData,
        ...state.searchForm,
      }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const searchData = () => {
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const pickerChange = (e, v) => {
      state.searchForm.yearMonth = v
    }
    const openTimeOutData = record => {
      state.startProvinceId = Number(record.queryId)
      state.title = record.name + `(${state.searchForm.yearMonth})`
      timeoutRef.value.windowShow = true
      timeoutRef.value.searchForm.startAddress.province = record.queryId
      setTimeout(() => {
        timeoutRef.value.setData()
        timeoutRef.value.loadData()
      }, 10)
    }

    return {
      ...toRefs(state),
      timeoutRef,
      loadData,
      searchData,
      resetData,
      setData,
      pickerChange,
      openTimeOutData
    }
  }
}
</script>

<style lang="less" scoped></style>
